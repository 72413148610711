import { Button, ThemeProps, Input, Text, Select } from "@deliverr/ui";
import styled from "@emotion/styled";
import { ShortShipReasonCode } from "@deliverr/non-compliance-client";
import { KittingEnumToHumanReadable, KittingInstructions, useSetReasonCodeAction } from "./useSetReasonCodeAction";

const StyledForm = styled.form<ThemeProps>`
  & > *:not(:last-child) {
    display: block;
    margin-bottom: ${({ theme }) => theme.spacing.S4};
  }
`;

export const SetReasonCodeAction: React.FC<{
  setReasonCode: (params: { reasonCode: ShortShipReasonCode; supportNotes: string | null }) => Promise<void>;
}> = ({ setReasonCode }) => {
  const { reasonCode, isSubmitting, supportNotes, onSubmit } = useSetReasonCodeAction({
    setReasonCode,
  });

  return (
    <StyledForm onSubmit={onSubmit}>
      <Text size="bodyLarge" bold>
        Set Reason Code
      </Text>
      <Text size="body">
        This tool is used to identify the reason code for the Short Ship issue type, and to provide instructions to the
        warehouse operator for what to do next
      </Text>
      <Select
        {...reasonCode.field}
        label="Reason Code"
        required
        hasError={!!reasonCode.fieldState.error}
        errorMessage={reasonCode.fieldState.error?.message}
        options={[
          { label: "Merchant Confirmed Short Ship", value: ShortShipReasonCode.MERCHANT_CONFIRMED_SHORTSHIP },
          { label: "Multiple Carrier Dropoffs", value: ShortShipReasonCode.MULTIPLE_CARRIER_DROPOFFS },
        ]}
      />

      <Select
        {...supportNotes.field}
        label="Notes for Warehouse Operator"
        hasError={!!supportNotes.fieldState.error}
        errorMessage={supportNotes.fieldState.error?.message}
        options={[
          {
            label: KittingEnumToHumanReadable[KittingInstructions.LEFTOVER_COMPONENTS_DISPOSED],
            value: KittingEnumToHumanReadable[KittingInstructions.LEFTOVER_COMPONENTS_DISPOSED],
          },
          {
            label: KittingEnumToHumanReadable[KittingInstructions.LEFTOVER_COMPONENTS_D2C],
            value: KittingEnumToHumanReadable[KittingInstructions.LEFTOVER_COMPONENTS_D2C],
          },
          {
            label: KittingEnumToHumanReadable[KittingInstructions.LEFTOVER_COMPONENTS_RS],
            value: KittingEnumToHumanReadable[KittingInstructions.LEFTOVER_COMPONENTS_RS],
          },
          {
            label: KittingEnumToHumanReadable[KittingInstructions.LEFTOVER_COMPONENTS_SHIP_TO_MERCHANT],
            value: KittingEnumToHumanReadable[KittingInstructions.LEFTOVER_COMPONENTS_SHIP_TO_MERCHANT],
          },
        ]}
      />

      <Button type="submit" loading={isSubmitting}>
        Submit Reason Code
      </Button>
    </StyledForm>
  );
};
