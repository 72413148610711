import { useState } from "react";
import { useForm, useController, SubmitHandler } from "react-hook-form";
import { handleNonComplianceError } from "utils/handleNonComplianceError";
import { logError } from "utils/logger";

type FormValues = {
  dsku: string;
  barcode: string;
};

export const useProductIdentificationAction = (params: {
  requireBarcode: boolean;
  onIdentifyProduct:
    | ((params: { dsku: string; barcode?: string }) => Promise<void>)
    | ((params: { dsku: string; barcode: string }) => Promise<void>)
    | null;
  onFailedToIdentify: () => Promise<void>;
}) => {
  const { requireBarcode, onFailedToIdentify, onIdentifyProduct } = params;
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { control, handleSubmit, reset } = useForm<FormValues>({});

  const dsku = useController({
    name: "dsku",
    defaultValue: "",
    control,
    rules: {
      required: true,
    },
  });

  const barcode = useController({
    name: "barcode",
    defaultValue: "",
    control,
    rules: {
      required: requireBarcode,
    },
  });

  const handleUnknownError = (error: Error) => {
    setIsSubmitting(false);
    handleNonComplianceError({ fn: "useProductIdentificationAction.onSubmit" }, error as Error);
  };

  const onSubmitForm: SubmitHandler<FormValues> = async (data) => {
    setIsSubmitting(true);
    try {
      if (onIdentifyProduct) {
        await onIdentifyProduct({ dsku: data.dsku, barcode: data.barcode });
        setIsSubmitting(false);
        reset();
      }
      return;
    } catch (error) {
      handleUnknownError(error as Error);
    }
  };

  const onFail = async () => {
    setIsSubmitting(true);
    try {
      await onFailedToIdentify();
      setIsSubmitting(false);
      reset();
      return;
    } catch (error) {
      handleUnknownError(error as Error);
    }
  };

  return {
    dsku,
    barcode,
    onSubmit: handleSubmit(onSubmitForm),
    onFail,
    isSubmitting,
  };
};
