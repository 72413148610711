import { toast } from "@deliverr/ui";
import { useClientsWithAuth } from "hooks/auth/useClientsWithAuth";
import { useState } from "react";
import { logError } from "utils/logger";
import { UnlabeledBoxData } from "@deliverr/legacy-inbound-client";
import { useAsyncFn, useMount } from "react-use";
import { groupBy } from "lodash";
import { useController, useForm } from "react-hook-form";
import { useOnFormValueChange } from "hooks/useOnFormValueChange";

type FormValues = {
  facilityId: string;
  dsku: string;
  sellerId: string;
};

export const useUnlabeledBoxTool = () => {
  const [boxesAwaitingAttribution, setBoxesAwaitingAttribution] = useState<UnlabeledBoxData[]>([]);
  const [filteredBoxes, setFilteredBoxes] = useState<UnlabeledBoxData[]>([]);
  const [selectedBox, setSelectedBox] = useState<string | null>(null);
  const { control, getValues, watch } = useForm<FormValues>({});
  const { inboundClient } = useClientsWithAuth();

  useOnFormValueChange(watch, () => {
    const { facilityId, dsku, sellerId } = getValues();
    if (!facilityId && !dsku && !sellerId) {
      setFilteredBoxes(boxesAwaitingAttribution);
      return;
    }
    const unlabeledBoxesByBoxLabel = groupBy(boxesAwaitingAttribution, "boxLabel");
    // Filter function to match the conditions
    const filterBoxes = (boxes: UnlabeledBoxData[]) => {
      return boxes.filter(
        (box) =>
          (!facilityId || box.facilityId.toLowerCase().includes(facilityId.toLowerCase().trim())) &&
          (!dsku || box.dsku.toLowerCase().includes(dsku.toLowerCase().trim())) &&
          (!sellerId || box.sellerId.toLowerCase().includes(sellerId.toLowerCase().trim()))
      );
    };

    // Applying the filter to each group
    const filteredGroups: Record<string, UnlabeledBoxData[]> = {};
    for (const [label, boxes] of Object.entries(unlabeledBoxesByBoxLabel)) {
      const filteredBoxes = filterBoxes(boxes);
      if (filteredBoxes.length > 0) {
        filteredGroups[label] = boxes;
      }
    }

    setFilteredBoxes(Object.values(filteredGroups).flat());
  });

  const facilityIdController = useController({
    name: "facilityId",
    control,
    defaultValue: "",
  });

  const dskuController = useController({
    name: "dsku",
    control,
    defaultValue: "",
  });

  const sellerIdController = useController({
    name: "sellerId",
    control,
    defaultValue: "",
  });

  useMount(() => {
    loadBoxesAwaitingAttribution();
  });

  const [{ loading: loadingBoxes }, loadBoxesAwaitingAttribution] = useAsyncFn(async () => {
    try {
      const boxes = (await inboundClient.getUnlabeledContainersAwaitingAttribution()).data;
      const sortedBoxes =
        boxes?.sort((a, b) => new Date(b.boxCreatedAt).getTime() - new Date(a.boxCreatedAt).getTime()) || [];
      setBoxesAwaitingAttribution(sortedBoxes);
      setFilteredBoxes(sortedBoxes);
    } catch (error) {
      logError({ fn: "useUnlabeledBoxTool.loadBoxesAwaitingAttribution" }, error as Error);
      toast.error("Failed to load boxes awaiting attribution");
    }
  }, [inboundClient, boxesAwaitingAttribution]);

  const unlabeledBoxesByBoxLabel = groupBy(filteredBoxes, "boxLabel");

  const onAssignedToShipment = (boxLabel: string) => {
    setSelectedBox(null);
    setBoxesAwaitingAttribution((prev) => prev.filter((box) => box.boxLabel !== boxLabel));
    setFilteredBoxes((prev) => prev.filter((box) => box.boxLabel !== boxLabel));
  };

  return {
    boxesAwaitingAttribution,
    loadingBoxes,
    setSelectedBox,
    selectedBox,
    unlabeledBoxesByBoxLabel,
    onAssignedToShipment,
    facilityIdController,
    dskuController,
    sellerIdController,
    filteredBoxes,
  };
};
