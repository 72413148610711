import { useAuth0 } from "@auth0/auth0-react";
import { AUTH_AUDIENCE } from "base/Auth";
import { useMemo } from "react";
import {
  ClientApi,
  setTokenFunctionInClients,
  inboundClient,
  sortationApiClient,
  nonComplianceClient,
  carrierNetworkClient,
  parcelClient,
} from "base/clients";

export const useClientsWithAuth = () => {
  const { getAccessTokenSilently } = useAuth0();

  return useMemo(() => {
    const clientsArray = [inboundClient, sortationApiClient, nonComplianceClient, carrierNetworkClient, parcelClient];
    const accessToken = getAccessTokenSilently({
      audience: AUTH_AUDIENCE,
    });
    setTokenFunctionInClients(clientsArray as ClientApi[], accessToken);

    return {
      inboundClient,
      sortationApiClient,
      nonComplianceClient,
      carrierNetworkClient,
      parcelClient,
    };
  }, [getAccessTokenSilently]);
};
