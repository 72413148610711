import { IssueGetResponse, NonComplianceClient } from "@deliverr/non-compliance-client";
import { IssueSpecificDetailsProps } from "./components/IssueSpecificDetails/IssueSpecificDetails";
import { ActionsProps } from "./components/Actions/Actions";

export async function getMissingContainerLabelDetails({
  issue,
  client,
}: {
  issue: IssueGetResponse;
  client: NonComplianceClient;
  setIsLoading: (isLoading: boolean) => void;
  refreshIssue: () => void;
}): Promise<{
  issueDetails: IssueSpecificDetailsProps;
  actionProps: Partial<ActionsProps> | null;
}> {
  const missingContainerLabelIssue = await client.getIssueMissingContainerLabelById({ issueId: issue.id });

  const issueDetails = {
    issue: missingContainerLabelIssue.issue,
    images: missingContainerLabelIssue.images,
    issueEntities: missingContainerLabelIssue.issueEntities,
    zendeskTickets: missingContainerLabelIssue.zendeskTickets,
    fields: [],
    reimbursementData: null,
    disputeData: null,
  };

  return {
    issueDetails,
    actionProps: {},
  };
}
