import { Button, Card, Text, ThemeProps } from "@deliverr/ui";
import styled from "@emotion/styled";
import { CardHeading } from "components/nonCompliance/components/CardHeading";
import { ProductIdentificationAction } from "./ProductIdentificationAction/ProductIdentificationAction";
import { IssueCancellationAction } from "./IssueCancellationAction/IssueCancellationAction";
import { ShortShipReasonCode } from "@deliverr/non-compliance-client";
import { SetReasonCodeAction } from "./SetReasonCodeAction/SetReasonCodeAction";
import { ReviewProductDimensions } from "./ReviewProductDimensions/ReviewProductDimensions";

export interface ActionsProps {
  dispose: (() => Promise<void>) | null;
  manualActionRequired: (() => Promise<void>) | null;
  markAsComplete: (() => Promise<void>) | null;
  resolveDispute: (() => Promise<void>) | null;
  identifyProductWithBarcode: ((params: { dsku: string; barcode: string }) => Promise<void>) | null;
  identifyProductWithoutBarcode: ((params: { dsku: string }) => Promise<void>) | null;
  onFailedToIdentifyProduct: (() => Promise<void>) | null;
  cancelIssue: ((reason: string) => Promise<void>) | null;
  setReasonCode: ((params: { reasonCode: ShortShipReasonCode; supportNotes: string | null }) => Promise<void>) | null;
  approveProductDimensions: (() => Promise<void>) | null;
  rejectProductDimensions: (() => Promise<void>) | null;
  isLoading: boolean;
}

const ActionList = styled.ul<ThemeProps>`
  li {
    margin-bottom: ${({ theme }) => theme.spacing.S4};
    padding-bottom: ${({ theme }) => theme.spacing.S4};
    border-bottom: 2px solid ${({ theme }) => theme.colors.NEUTRAL[60]};

    Button {
      margin-top: ${({ theme }) => theme.spacing.S4};
    }
  }

  li:last-child {
    border-bottom: none;
  }
`;

export const Actions = ({
  dispose,
  manualActionRequired,
  markAsComplete,
  resolveDispute,
  isLoading,
  identifyProductWithBarcode,
  identifyProductWithoutBarcode,
  onFailedToIdentifyProduct,
  cancelIssue,
  setReasonCode,
  approveProductDimensions,
  rejectProductDimensions,
}: Pick<ActionsProps, "isLoading"> & Partial<ActionsProps>) => {
  return (
    <Card>
      <CardHeading>Action(s)</CardHeading>
      {!dispose &&
        !manualActionRequired &&
        !markAsComplete &&
        !resolveDispute &&
        !identifyProductWithBarcode &&
        !identifyProductWithoutBarcode &&
        !onFailedToIdentifyProduct &&
        !setReasonCode &&
        !approveProductDimensions &&
        !rejectProductDimensions && <Text size="body">No available actions</Text>}
      {dispose && manualActionRequired && (
        <DispositionActions dispose={dispose} manualActionRequired={manualActionRequired} isLoading={isLoading} />
      )}
      {markAsComplete && <MarkAsCompleteAction markAsComplete={markAsComplete} isLoading={isLoading} />}
      {resolveDispute && <DisputeResolution resolveDispute={resolveDispute} isLoading={isLoading} />}
      {onFailedToIdentifyProduct && (identifyProductWithBarcode || identifyProductWithoutBarcode) && (
        <ProductIdentificationAction
          onFailedToIdentify={onFailedToIdentifyProduct}
          onIdentifyProductWithBarcode={identifyProductWithBarcode ?? null}
          onIdentifyProductWithoutBarcode={identifyProductWithoutBarcode ?? null}
        />
      )}
      {setReasonCode && <SetReasonCodeAction setReasonCode={setReasonCode} />}
      {approveProductDimensions && rejectProductDimensions && (
        <ReviewProductDimensions
          approveProductDimensions={approveProductDimensions}
          rejectProductDimensions={rejectProductDimensions}
          isLoading={isLoading}
        />
      )}
      {cancelIssue && <IssueCancellationAction cancelIssue={cancelIssue} />}
    </Card>
  );
};

interface DispositionActionsProps {
  dispose: () => Promise<void>;
  manualActionRequired: () => Promise<void>;
  isLoading: boolean;
}

const DispositionActions = ({ dispose, manualActionRequired, isLoading }: DispositionActionsProps) => {
  return (
    <>
      <Text size="body">
        This issue was unable to be reimbursed automatically, please coordinate with the merchant via the Zendesk ticket
        and decide on the next step. Choose one of the following actions:
      </Text>
      <br />
      <ActionList>
        <li>
          <Text size="body">The merchant has agreed to dispose of the damaged units.</Text>
          <Button onClick={dispose} loading={isLoading}>
            Approve site disposal
          </Button>
        </li>
        <li>
          <Text size="body">
            This issue needs more investigation and support will coordinate with operations on what the next steps will
            be via Zendesk.
          </Text>
          <Button onClick={manualActionRequired} loading={isLoading}>
            Manual action is required
          </Button>
        </li>
      </ActionList>
    </>
  );
};

interface MarkAsCompleteActionProps {
  markAsComplete: () => void;
  isLoading: boolean;
}

const MarkAsCompleteAction = ({ markAsComplete, isLoading }: MarkAsCompleteActionProps) => {
  return (
    <>
      <Text size="body" bold>
        Complete issue:
      </Text>
      <Text size="body">If all the work has been completed on this issue please mark it as complete.</Text>
      <br />
      <Button onClick={markAsComplete} loading={isLoading}>
        Mark as complete
      </Button>
    </>
  );
};

interface DisputeResolutionProps {
  resolveDispute: () => void;
  isLoading: boolean;
}

const DisputeResolution = ({ resolveDispute, isLoading }: DisputeResolutionProps) => {
  return (
    <>
      <Text size="body" bold>
        Resolve dispute:
      </Text>
      <Text size="body">If the merchant is satisfied with the dispute resolution, please mark it as resolved.</Text>
      <br />
      <Button onClick={resolveDispute} loading={isLoading}>
        Resolve dispute
      </Button>
    </>
  );
};
